import { title } from 'process';
import { images } from './Images';

export interface IBanner {
  id: number;
  bg?: IBackground;
  smallTitle?: string;
  title?: string;
  text?: string;
  link: string;
  largeTitle?: boolean;
  square: boolean;
  hideContent?: boolean;
  className?: string;
  active: boolean;
}

interface IBackground {
  desktop: string;
  mobile: string;
}

export const bannerSelection = {
  nordea_finans: {
    id: 1,
    bg: images.nordea_finans,
    largeTitle: true,
    title: 'Har du finansieringsbeviset klart?',
    text: 'Husk at budrunden starter rett etter visning',
    link: 'https://www.nordea.no/privat/vare-produkter/lan-og-kreditt/boliglan/finansieringsbevis-ha-det-klart-til-visning.html?cid=partner-ex2v5szn71',
    square: false,
    active: true
  },
  meglere: {
    id: 3,
    bg: images.panter_bg,
    smallTitle: '',
    title: 'VÅRE MEGLERE',
    text: '',
    link: 'https://privatmegleren.no/kontorer',
    square: true,
    active: false
  },
  selge: {
    id: 4,
    bg: images.kjop,
    smallTitle: '',
    text: 'La en av våre meglere bistå med salget',
    link: 'https://privatmegleren.no/verdivurdering',
    square: false,
    active: true
  },
  verdivurdering: {
    id: 5,
    bg: images.panter_bg,
    smallTitle: '',
    title: 'Hva er din bolig verdt?',
    text: 'La en av våre meglere bistå med salg av din bolig',
    link: 'https://privatmegleren.no/verdivurdering',
    square: false,
    active: true
  },
  lignende: {
    id: 6,
    bg: images.falk_bg,
    smallTitle: '',
    title: 'Se lignende eiendommer',
    text: 'Fant du ikke det du ønsket?',
    link: 'https://privatmegleren.no/kjope-bolig',
    square: false,
    active: false
  },
  register: {
    id: 7,
    bg: images.hjort_bg,
    title: 'Registrer deg i vårt boligsøkerregister',
    text: 'Fant du ikke det du ønsket?',
    link: 'https://privatmegleren.no/',
    square: false,
    active: false
  },
  verdi: {
    id: 10,
    bg: images.verdi,
    link: 'https://privatmegleren.no/settprispå/?pm=51028',
    square: false,
    active: true
  },
  spoross: {
    id: 17,
    bg: images.spoross,
    link: 'https://privatmegleren.no/content/sporross',
    square: false,
    active: true
  },
  presisjon: {
    id: 19,
    bg: images.presisjon,
    link: 'https://privatmegleren.no/kampanje/presisjon?utm_source=Egenannonsering&utm_medium=Visning&utm_content=5325&utm_campaign=Presisjon&utm_term=&pm=5325',
    square: false,
    active: true
  },
  detaljerSomSelger1: {
    id: 20,
    text: 'Detaljene som selger',
    bg: images.detaljerSomSelger1,
    link: 'https://privatmegleren.no/kampanje/detaljene-som-selger?utm_source=Egenannonsering&utm_medium=Visning&utm_content=5357&utm_campaign=Detaljene_som_selger&utm_term=&pm=5357',
    square: false,
    active: true
  },
  detaljerSomSelger2: {
    id: 21,
    text: 'Detaljene som selger',
    bg: images.detaljerSomSelger2,
    link: 'https://privatmegleren.no/kampanje/detaljene-som-selger?utm_source=Egenannonsering&utm_medium=Visning&utm_content=5357&utm_campaign=Detaljene_som_selger&utm_term=&pm=5357',
    square: false,
    active: true
  },
  detaljerSomSelger3: {
    id: 22,
    text: 'Detaljene som selger',
    bg: images.detaljerSomSelger3,
    link: 'https://privatmegleren.no/kampanje/detaljene-som-selger?utm_source=Egenannonsering&utm_medium=Visning&utm_content=5357&utm_campaign=Detaljene_som_selger&utm_term=&pm=5357',
    square: false,
    active: true
  },
  presisjon2: {
    id: 23,
    text: 'Selg boligen din med Presisjon',
    bg: images.presisjon2,
    link: 'https://privatmegleren.no/kampanje/presisjon?utm_source=Egenannonsering&utm_medium=adform&utm_content=5363&utm_campaign=Presisjon24&utm_term=&pm=5363',
    square: false,
    active: true
  },
  forstegangesselgeren: {
    id: 24,
    text: 'Når livet skjer, tar vi oss av detaljene. Om du skal selge for første, andre eller siste gang.',
    bg: images.forstegangsselgeren,
    link: 'https://privatmegleren.no/kampanje/forstegangsselgeren/alle?utm_source=Egenannonsering&utm_medium=adform&utm_content=5364&utm_campaign=forstegangsselgeren&utm_term=&pm=5364',
    square: false,
    active: true
  },
  forstegangesselgeren2: {
    id: 25,
    text: 'Når livet skjer, tar vi oss av detaljene. Om du skal selge for første, andre eller siste gang.',
    bg: images.forstegangsselgeren2,
    link: 'https://privatmegleren.no/kampanje/forstegangsselgeren/alle?utm_source=Egenannonsering&utm_medium=adform&utm_content=5364&utm_campaign=forstegangsselgeren&utm_term=&pm=5364',
    square: false,
    active: true
  },
  forstegangesselgeren3: {
    id: 24,
    text: 'Når livet skjer, tar vi oss av detaljene. Om du skal selge for første, andre eller siste gang.',
    bg: images.forstegangsselgeren3,
    link: 'https://privatmegleren.no/kampanje/forstegangsselgeren/alle?utm_source=Egenannonsering&utm_medium=adform&utm_content=5364&utm_campaign=forstegangsselgeren&utm_term=&pm=5364',
    square: false,
    active: true
  },
  forstegangesselgeren4: {
    id: 25,
    text: 'Når livet skjer, tar vi oss av detaljene. Om du skal selge for første, andre eller siste gang.',
    bg: images.forstegangsselgeren4,
    link: 'https://privatmegleren.no/kampanje/forstegangsselgeren/alle?utm_source=Egenannonsering&utm_medium=adform&utm_content=5364&utm_campaign=forstegangsselgeren&utm_term=&pm=5364',
    square: false,
    active: true
  },
  artikkelsideNordea: {
    id: 26,
    title: 'Nyttig å vite til budrunden',
    bg: images.artikkelsideNordea,
    link: 'https://privatmegleren.no/budrunden',
    square: false,
    active: true
  }
};
